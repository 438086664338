import React, { memo, useState, useCallback } from 'react';
import { Card, Stack } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

import ReportNav from './ReportNav';
import { DataGrid } from '..';

const Index = memo(({
    columns,
    data,
    isFecthing,
    setSelectedRow,
    onCellKeyDown,
    di,
    df,
    setDi,
    setDf,
    getData,
    buttonsToRows = [],
    buttonsToRow = [],
    beforeFilter,
    addFilters = [],
    height = "45vh"
}) => {

    const navItemsFilter = [];

    // Adicionando o estado openNav
    const [openNav, setOpenNav] = useState(false);
    
    const handleOpenCompose = useCallback(() => {
        setOpenNav(true);  // Corrigindo para usar o estado correto
    }, []);

    const handleCloseNav = useCallback(() => {
        setOpenNav(false);
    }, []);

    const Toolbar = useCallback(() => (
        <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{ ml: 0.5, mr: 0.5, mt: 0.5 }}
        >
            <GridToolbarQuickFilter
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
                sx={{ mr: 0.5 }}
            />
        </Stack>
    ), []);

    return (
        <Card
            sx={{
                height: height,
                display: 'flex',
                bgcolor: 'background.neutral'
            }}
        >
            <ReportNav
                items={navItemsFilter}
                openNav={openNav}
                onCloseNav={handleCloseNav}
                onOpenCompose={handleOpenCompose}
                di={di}
                df={df}
                setDi={setDi}
                setDf={setDf}
                getData={getData}
                buttonsToRows={buttonsToRows}
                buttonsToRow={buttonsToRow}
                beforeFilter={beforeFilter}
                addFilters={addFilters}
            />
            <Stack flexGrow={1}>
                <DataGrid
                    columns={columns}
                    data={data}
                    loading={isFecthing}
                    onRowClick={(e) => setSelectedRow(e)}
                    onCellKeyDown={onCellKeyDown}
                    height={'100%'}
                    isCompact
                    toolbar={Toolbar}
                />
            </Stack>
        </Card>
    );
});

export default Index;

