import './locales/i18n';

import 'simplebar/src/simplebar.css';
import 'src/config/styles.css';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'leaflet/dist/leaflet.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from './auth/JwtContext';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

import queryClient from './services/query';
import { APP_PATH_MAIN } from './config/constants';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<AuthProvider>
		<QueryClientProvider client={queryClient}>
			<HelmetProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<SettingsProvider>
						<BrowserRouter basename={APP_PATH_MAIN}>
							<ScrollToTop />
							<App />
							<ReactQueryDevtools initialIsOpen={false} />
						</BrowserRouter>
					</SettingsProvider>
				</LocalizationProvider>
			</HelmetProvider>
		</QueryClientProvider>
	</AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
